/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type AllowedFiles = "userEmail" | "merchant-onboarding";

type AllowedType = "string" | "object";

export function save(
  field: AllowedFiles,
  value: any,
  saveType: AllowedType = "object"
): void {
  if (saveType === "object") {
    localStorage.setItem(field, JSON.stringify(value));
  } else {
    localStorage.setItem(field, value);
  }
}

export function read<T>(
  field: AllowedFiles,
  returnType: AllowedType = "string"
): T | any {
  const value = localStorage.getItem(field);
  if (value) {
    if (returnType === "object") {
      const data = JSON.parse(value);
      return data as T;
    }
  }
  return value;
}
